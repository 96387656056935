import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MainNavLink from './MainNavLink';

const InnerWrapper = styled.ul`
  white-space: nowrap;
  font-size: 0;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 0px;
  ${props =>
    props.level !== 0 &&
    `
    background-color: ${props.theme.color.bgSecondary};
    position: absolute;
    min-width: 160px;
    left: 0;
    display: none;
  `};
`;

const Nav = ({ className, links = [], level = 0 }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [menuRefs] = useState(links.map(_ => createRef()));

  return (
    <InnerWrapper className={className} level={level}>
      {links.map((link, i) => (
        <MainNavLink
          navRef={menuRefs[i]}
          key={i}
          level={level}
          mobileOnly={link.mobileOnly}
          path={link.path}
          text={link.text}
          subNav={link.subNav}
          active={activeMenu === link.text}
          setActive={setActiveMenu}
        />
      ))}
    </InnerWrapper>
  );
};

Nav.propTypes = {
  className: PropTypes.string,
  level: PropTypes.number,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      text: PropTypes.string.isRequired,
      subNav: PropTypes.array
    })
  ).isRequired
};

export default Nav;
