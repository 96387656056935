import React from 'react';
import styled from 'styled-components';

import BrandStatement from './BrandStatement';
import LinkList from './LinkList';
import LogosBlock from './LogosBlock';
import SocialIcons from './SocialIcons';

const submenuLinks = [
  { url: '/news', text: 'News' },
  { url: '/about', text: 'About Us' },
  { url: '/contact', text: 'Contact Us' },
  { url: 'https://www.waterheaterrecall.com/', text: 'Recall Notice' },
  { url: 'https://www.aosmith.com/Privacy-Policy/', text: 'Privacy Policy' },
  {
    url: 'https://www.aosmith.com/Utility-Pages/Terms-and-Conditions/',
    text: 'Terms and Conditions'
  },
  {
    className: 'optanon-toggle-display',
    text: 'Do Not Sell or Share My Info'
  },
  { url: '/careers', text: 'Careers' },
  { url: '/rebate-center', text: 'Rebate Center' }
];

const otherSiteLinks = [
  { url: '/lochinvarpov', text: 'LochinvarPOV.com' },
  { url: '//lochinvaru.com', text: 'LochinvarU.com' },
  { url: '//www.lochinvargear.com', text: 'LochinvarGear.com' }
];

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: 5.5% calc(9% - 20px) 7%;

  > * {
    margin: 10px 20px;
  }

  @media (max-width: ${props => props.theme.deviceWidth.mobilePhone}) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
`;

const FooterTop = () => (
  <Wrapper>
    <BrandStatement />
    <LinkList heading="About Lochinvar" links={submenuLinks} />
    <LinkList heading="Visit Our Other Sites" links={otherSiteLinks} />
    <LogosBlock
      appStore={{
        src: '/images/ios-app-store.png',
        alt: 'iOS App Store',
        href: 'https://itunes.apple.com/app/id891482782'
      }}
      googlePlay={{
        src: '/images/android-app-store.png',
        alt: 'Android App Store',
        href: 'https://play.google.com/store/apps/details?id=com.lochinvar.serf&hl=en'
      }}
      image={{
        src: '/images/ConXusLogo.png',
        alt: 'ConXus Logo'
      }}
    />
    <SocialIcons />
  </Wrapper>
);

export default FooterTop;
