import React from 'react';

const RightCaret = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewport="0 0 10 15"
    width="8px"
    height="8px"
  >
    <path
      d="M1.13798 7.80465L4.94265 3.99998L1.13798 0.195312L0.195312 1.13798L3.05731 3.99998L0.195312 6.86198L1.13798 7.80465Z"
      fill="currentColor"
    />
  </svg>
);

export default RightCaret;
