import { query } from '../../../../globalUtils/api';
import suggest from './suggest.gql';

const getDefaultOption = search => ({
  id: search,
  path: `/search/?term=${search}`,
  suggestion: search
});

export const getSuggestions = async search => {
  if (!search) {
    return Promise.resolve([]);
  }
  const { suggest: suggestions } = await query(suggest, {
    variables: { query: search }
  });
  return [getDefaultOption(search), ...suggestions];
};

export const navigateToOption = option => {
  window.location.href = option.path;
};
