/* TODO
 * I have hopes that we can clean up the theme file some by slowly moving actual
 * colors to this "swatches" object and limiting them to only a few colors.
 * Then we can create a more detailed colors object that isn't just used
 * randomly throughout the app in ways that don't make sense and that utilize
 * the values from the swatches.
 *
 * For example, the button component would have a button background color
 * property on the color object instead of using primary directly.
 */
export const swatches = {
  black: '#323232',
  gray: {
    dark: '#424242',
    medium: '#959799',
    light: '#dadbdd'
  },
  offwhite: '#f2f2f2',
  primary: '#eb2c31',
  white: '#fff',
  blue: '#0047AB'
};

export const color = {
  primary: swatches.primary,
  secondary: '#000',
  tertiary: swatches.white,
  primaryFaded: '#ff6d6a',
  primaryDarkened: '#e20003',
  calloutSecondary: '#acafb1',
  bgPrimary: '#f8f8f8',
  bgSecondary: swatches.black,
  borderPrimary: swatches.gray.light,
  borderPrimaryFaded: '#f4f5f7',
  borderPrimaryDarkened: '#c1c2c4',
  borderPrimaryOverlay: '#4b4b4b',
  borderSecondary: '#d7d7d7',
  buttonPrimary: swatches.primary,
  buttonPrimaryHover: '#d1272c',
  buttonSecondary: swatches.white,
  buttonSecondaryHover: swatches.white,
  headerPrimary: swatches.gray.dark,
  headerSecondary: '#7b7d7f',
  headerSecondaryFaded: swatches.gray.medium,
  headerSecondaryDarkened: '#484a4c',
  invalidBackground: '#fde8e8',
  footerHeading: 'rgba(255, 255, 255, 0.7)',
  formControlBackgroundColor: swatches.white,
  linkPrimary: swatches.white,
  linkPrimaryDarkened: '#e6e6e6',
  listHeaderPrimary: '#d0d0d0',
  offWhite: swatches.offwhite,
  nav: {
    linkColor: swatches.gray.medium,
    linkHighlight: swatches.white
  },
  primaryInner: '#f59290',
  shadowPrimary: 'rgba(83, 83, 83, 0.05)',
  shadowSecondary: 'rgba(0, 0, 0, 0.2)',
  textPrimary: '#616161',
  textSecondary: '#9da1a4',
  textLight: swatches.white,
  selectArrow: '#898a8d',
  placeholderText: '#acafb1',
  transparentWhite: 'rgba(255, 255, 255, 0.7)',
  semiTransparentWhite: 'rgba(255, 255, 255, 0.9)',
  stepperLabel: '#cfd7de',
  stepperIndicator: '#e3e8ec',
  stepperIndicatorWarning: '#f1c40f'
};

export const components = {
  mobileSelect: {
    arrowSize: 16,
    backgroundActive: color.primary,
    backgroundInactive: swatches.offwhite,
    colorActive: swatches.white,
    colorInactive: '#4a4a4a'
  },
  radio: {
    color: swatches.primary,
    size: 8
  },
  slider: {
    disabledColor: '#D8D8D8'
  }
};

export const dimension = {
  innerMax: '1170px',
  outerMax: '1440px'
};

export const deviceWidthValues = {
  mobilePhone: 450,
  largePhone: 600,
  tablet: 800,
  desktop: 1240
};

export const deviceWidth = {
  mobilePhone: `${deviceWidthValues.mobilePhone}px`,
  largePhone: `${deviceWidthValues.largePhone}px`,
  tablet: `${deviceWidthValues.tablet}px`,
  desktop: `${deviceWidthValues.desktop}px`
};

export const font = {
  metaOt:
    "'Merriweather Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  montserrat:
    "'Montserrat', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  sourceSans:
    "'Source Sans Pro', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
};

export const fontWeight = {
  black: '900',
  bold: '700',
  semibold: '600',
  regular: '400',
  light: '300',
  extralight: '200'
};

export const media = {
  largePhone: `(max-width: ${deviceWidth.largePhone})`,
  mobilePhone: `(max-width: ${deviceWidth.mobilePhone})`,
  tablet: `(max-width: ${deviceWidth.tablet})`,
  desktop: `(max-width: ${deviceWidth.desktop})`
};

export const product = {
  specs: {
    borderColor: swatches.gray.medium,
    circles: {
      activeColor: '#4a4a4a',
      color: '#d8d8d8'
    },
    color: swatches.black,
    headerBackground: swatches.gray.dark,
    headerColor: swatches.white,
    imagesBackground: swatches.offwhite,
    modelSelect: {
      background: swatches.offwhite,
      color: '#4a4a4a'
    }
  },
  tabs: {
    activeColor: swatches.primary,
    background: swatches.offwhite,
    color: '#9b9b9b',
    height: 61, // in pixels
    hoverColor: swatches.primary,
    transitionSpeed: 200 // in ms
  }
};

export const spacing = {
  gutter: '30px',
  gutterHalf: '15px'
};

export const time = {
  fast: '200ms',
  default: '400ms',
  slow: '800ms'
};

export default {
  color,
  components,
  dimension,
  deviceWidth,
  deviceWidthValues,
  font,
  fontWeight,
  media,
  product,
  spacing,
  time
};
