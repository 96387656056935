import React from 'react';
import styled from 'styled-components';

import FooterTop from './FooterTop';
import FooterCopyright from './FooterCopyright';

const Wrapper = styled.div`
  background-image: linear-gradient(to bottom right, #707070, #404040);
  position: relative;

  @media print {
    display: none;
  }
`;

const Footer = () => (
  <Wrapper id="footer">
    <FooterTop />
    <FooterCopyright />
  </Wrapper>
);

export default Footer;
