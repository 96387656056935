import React from 'react';
import styled from 'styled-components';
import { color } from '../../../styles/theme';
import SearchIcon from '../icons/Search';
import showSwiftype from './utils';

const Wrapper = styled.form`
  border: 1px solid ${p => p.theme.color.borderPrimaryDarkened};
  border-radius: 5px;
  max-width: 250px;
  padding: 2px 7px;

  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
  align-items: center;

  @media ${p => p.theme.media.desktop} {
    display: none;
  }
`;

const SearchButton = styled.button`
  border: none;
  background-color: ${p => p.theme.color.borderPrimaryOverlay};
  height: 13px;
  margin-top: -2px;
  padding-right: 16px;
  width: 13px;
`;

const SearchBox = styled.input`
  &&& {
    border: none;
    background-color: ${p => p.theme.color.borderPrimaryOverlay};
    background-image: none;
    color: ${p => p.theme.color.textLight};
    padding: 5px;

    &::placeholder {
      color: ${p => p.theme.color.textSecondary};
    }
  }
`;

class SearchBarSwiftype extends React.PureComponent {
  render() {
    return (
      <Wrapper
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <SearchBox type="text" id="search-bar-input" placeholder="Search" />
        <SearchButton
          type="submit"
          name="submit"
          onClick={() =>
            showSwiftype(document.getElementById('search-bar-input').value)
          }
        >
          <SearchIcon
            fill={color.borderPrimaryDarkened}
            height={13}
            width={13}
          />
        </SearchButton>
      </Wrapper>
    );
  }
}

export default SearchBarSwiftype;
