import styled from 'styled-components';
import H1 from './H1';

const BlockTitle = styled(H1)`
  border-bottom: 1px solid ${props => props.theme.color.borderPrimary};
  margin: ${p => p.topMargin ? '80px' : 0} 0 12px 0;
  padding-bottom: 10px;

  @media (max-width: ${props => props.theme.deviceWidth.largePhone}) {
    margin: ${p => p.topMargin ? '30px' : 0} 0 12px 0;
  }
`;

export default BlockTitle;
