import React from 'react';
import styled from 'styled-components';

import { Heading } from './FooterHeading';
import Facebook from '../icons/Facebook';
import Twitter from '../icons/Twitter';
import Youtube from '../icons/Youtube';

const IconWrapper = styled.div`
  white-space: nowrap;

  a + a {
    margin: 0 0 0 20px;
  }
`;

const SocialHeading = styled(Heading)`
  @media (max-width: ${props => props.theme.deviceWidth.mobilePhone}) {
    display: none;
  }
`;

const SocialIcons = () => (
  <div>
    <SocialHeading>
        Social
    </SocialHeading>
    <IconWrapper>
      <Facebook />
      <Twitter />
      <Youtube />
    </IconWrapper>
  </div>
);

export default SocialIcons;
