import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import RightCaret from '../icons/RightCaret';

const Caret = styled(RightCaret)`
  width: 10px;
  height: 90%;
`;

const StyledButton = styled.button`
  cursor: pointer;
  background-color: ${props =>
    props.disabled
      ? props.theme.color.primaryInner
      : props.theme.color.buttonPrimary};
  border: 2px solid
    ${props =>
    props.disabled
      ? props.theme.color.primaryInner
      : props.theme.color.buttonPrimary};
  border-radius: 0;
  color: ${props => props.theme.color.linkPrimary};
  font-size: 16px;
  font-family: ${props => props.theme.font.sourceSans};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  padding: 12px 0;
  width: ${props => (props.full ? '100%' : '49%')};
  ${props => !props.full && 'max-width: 260px;'}
  letter-spacing: 0.5px;
  transition: 0.5s;
  -webkit-font-smoothing: antialiased;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    visibility: hidden;
    margin-left: 8px;
    margin-right: -16px;
  }

  &:hover {
    background-color: ${props =>
    props.disabled
      ? props.theme.color.primaryInner
      : props.theme.color.buttonPrimaryHover};
    border-color: ${props =>
    props.disabled
      ? props.theme.color.primaryInner
      : props.theme.color.buttonPrimaryHover};

    svg {
      visibility: visible;
    }
  }

  @media (max-width: ${props => props.theme.deviceWidth.mobilePhone}) {
    display: block;
    width: 100%;
    max-width: none;
  }
`;

const Button = props => (
  <StyledButton {...props}>
    {props.children}
    {props.hideCaret || <Caret />}
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.node,
  hideCaret: PropTypes.bool
};

export default Button;
