/**
 * HACK
 * this function is used to open or close the swiftype modal
 * @param {string} queryText - text to search for
 */
export default function showSwiftype(queryText = null) {
  if (typeof window !== 'undefined') {
    if (
      window &&
      window._st &&
      window._st._widgetManager &&
      window._st._widgetManager._defaultInstall &&
      window._st._widgetManager._defaultInstall._searchContext
    ) {
      // set results window visible
      window._st._widgetManager._defaultInstall._searchContext._resultsDisplay.setVisible(
        true
      );

      // if there is a query add it to the swiftype input and send an enter keypress
      if (queryText) {
        const searchBox = document.getElementById('st-overlay-search-input');
        searchBox.value = queryText;
        const enterEvent = new KeyboardEvent('keydown', {
          code: 'Enter',
          key: 'Enter',
          charCode: 13,
          keyCode: 13
        });
        searchBox.dispatchEvent(enterEvent);
      }
    }
  }
}
