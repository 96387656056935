import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BaseNavLink from '../../../../../NavLink';

const NavLink = styled(BaseNavLink)`
  display: block;
  padding: 6px ${p => (p.hasSubLinks ? '36px' : '24px')} 6px
    ${p => (p.sub ? '24px' : '12px')};
  position: relative;
  color: ${p =>
    p.selected ? p.theme.color.nav.linkHighlight : p.theme.color.nav.linkColor};
  transition: color 180ms ease-in-out;

  &:hover {
    color: ${p => p.theme.color.nav.linkHighlight};
  }

  &::before,
  &::after {
    background-color: ${p =>
    p.selected
      ? p.theme.color.nav.linkHighlight
      : p.theme.color.nav.linkColor};
    transition: background-color 180ms ease-in-out;
    content: "";
    display: ${p => (p.hasSubLinks ? 'initial' : 'none')};
    width: 6px;
    height: 2px;
    border-radius: 50px;
    position: absolute;
  }
  &:hover::before,
  &:hover::after {
    background-color: ${p => p.theme.color.nav.linkHighlight};
  }
  &::before {
    transform: rotate(45deg);
    right: 8px;
    top: calc(50% - 2px);
  }
  &::after {
    transform: rotate(-45deg);
    right: 8px;
    top: calc(50% + 1px);
  }
`;

class Link extends React.PureComponent {
  onHover = () => {
    const { link, onHover } = this.props;
    if (onHover) onHover(link);
  };

  render() {
    const { active, link, sub } = this.props;

    return (
      <div onMouseEnter={this.onHover}>
        <NavLink
          to={link.path}
          exact
          sub={sub}
          hasSubLinks={link.subNav}
          selected={active}
        >
          {link.text}
        </NavLink>
      </div>
    );
  }
}

Link.propTypes = {
  active: PropTypes.bool,
  link: PropTypes.shape({
    path: PropTypes.string.isRequired,
    subNav: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired
      })
    ),
    text: PropTypes.string.isRequired
  }),
  onHover: PropTypes.func,
  sub: PropTypes.bool
};

export default Link;
