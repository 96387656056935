import styled from 'styled-components';

const Icon = styled.a`
  cursor: pointer;
  svg {
    .stroke {
      transition: stroke 80ms ease-in-out;
    }
    .fill {
      transition: fill 80ms ease-in-out;
    }
    ${props =>
    !props.noHover
      ? `&:hover {
      .stroke {
        stroke: ${props.theme.color.primary};
      }
      .fill {
        fill: ${props.theme.color.primary};
      }
    }`
      : ''};
  }
`;

export default Icon;
