import styled from 'styled-components';

export default styled.label`
  display: block;
  margin: ${props => props.margin || 0} 0;

  color: ${props => props.invalid ? props.theme.color.primary : props.theme.color.headerPrimary};
  font-weight: ${props => props.theme.fontWeight.semibold};
  font-family: ${props => props.theme.font.sourceSans};
  font-size: 12px;
  line-height: 1.25;
  letter-spacing: 0.1px;
  text-transform: uppercase;
`;
