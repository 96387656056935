/* eslint-disable import/default */
/* eslint-disable import/first */
import 'normalize.css';
import './styles/global';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';

import App from './App';

if (typeof window !== 'undefined') {
  require('intersection-observer');
}

if (typeof document !== 'undefined') {
  const appElement = document.getElementById('root');
  const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;
  const render = Comp => {
    renderMethod(
      <AppContainer>
        <Comp />
      </AppContainer>,
      appElement
    );
  };

  // Render!
  render(App);

  // Hot Module Replacement
  if (module.hot) {
    module.hot.accept('./App', () => {
      render(require('./App').default);
    });
  }
}

// Export top level component as JSX (for static rendering)
export default App;
