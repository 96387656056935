export default [
  {
    benefits: 'You will learn the features and benefits of the Lochinvar commercial water heater product line, how to select the product best suited for a specific application, and how to correctly size the water heater to provide adequate volumes of hot water at a desired temperature.',
    dates: [
      {
        availableSeats: 0,
        end: (new Date()).toString(),
        id: 1,
        start: (new Date()).toString()
      },
      {
        availableSeats: 1,
        end: (new Date()).toString(),
        id: 2,
        start: (new Date()).toString()
      },
      {
        availableSeats: 10,
        end: (new Date()).toString(),
        id: 3,
        start: (new Date()).toString()
      }
    ],
    description: 'Duis massa est, placerat vitae sapien eget, egestas accumsan ante. Ut tempus pharetra felis, in hendrerit magna auctor sed. Duis imperdiet aliquet lobortis. Etiam ullamcorper metus ut mauris rhoncus congue. Sed cursus elementum ullamcorper. Sed vitae velit malesuada, lacinia dolor quis, faucibus nulla. Morbi semper neque sit amet ante congue, ac commodo lacus fermentum. Fusce tincidunt vestibulum arcu, dictum mattis metus convallis non.',
    id: 1,
    products: 'The Full Line of Lochinvar Water Heaters. Special Emphasis on Gas Fired Commercial Water Heaters.',
    title: 'Commercial Water Heater Sizing School',
    tuition: 'No tuition charge. Class size is limited. Reservations must be received no later than four weeks prior to course date. Please contact Kayne Storey @ ext. 2103',
    who: 'Individuals involved in commercial water heater sales and those involved in selection and sizing of water heaters.'
  },
  {
    benefits: 'You will learn the features and benefits of the Lochinvar commercial water heater product line, how to select the product best suited for a specific application, and how to correctly size the water heater to provide adequate volumes of hot water at a desired temperature.',
    dates: [
      {
        availableSeats: 0,
        end: (new Date()).toString(),
        id: 4,
        start: (new Date()).toString()
      },
      {
        availableSeats: 1,
        end: (new Date()).toString(),
        id: 5,
        start: (new Date()).toString()
      },
      {
        availableSeats: 10,
        end: (new Date()).toString(),
        id: 6,
        start: (new Date()).toString()
      }
    ],
    description: 'Duis massa est, placerat vitae sapien eget, egestas accumsan ante. Ut tempus pharetra felis, in hendrerit magna auctor sed. Duis imperdiet aliquet lobortis. Etiam ullamcorper metus ut mauris rhoncus congue. Sed cursus elementum ullamcorper. Sed vitae velit malesuada, lacinia dolor quis, faucibus nulla. Morbi semper neque sit amet ante congue, ac commodo lacus fermentum. Fusce tincidunt vestibulum arcu, dictum mattis metus convallis non.',
    id: 2,
    products: 'The Full Line of Lochinvar Water Heaters. Special Emphasis on Gas Fired Commercial Water Heaters.',
    title: 'Residential Water Heater Sizing School',
    tuition: 'No tuition charge. Class size is limited. Reservations must be received no later than four weeks prior to course date. Please contact Kayne Storey @ ext. 2103',
    who: 'Individuals involved in commercial water heater sales and those involved in selection and sizing of water heaters.'
  }
];
