import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InnerWrapper from '../InnerWrapper';

const CCWrapper = styled.div`
  margin: ${props => (props.pullUp ? '-45px 0 50px' : '100px 0 50px 0')};
  background-color: ${props =>
    props.bgColor
      ? props.theme.color[props.bgColor]
      : props.theme.color.linkPrimary};
  box-shadow: 5px 5px 25px 0 ${props => props.theme.color.shadowPrimary};
  /* Relative positioning has been added to allow the card to render over the
     Hero when it gets pulled up */
  position: relative;
  @media (max-width: ${props => props.theme.deviceWidth.mobilePhone}) {
    box-shadow: 0 2px 4px 0 ${props => props.theme.color.shadowSecondary};
    margin: ${props => (props.pullUp ? '-45px 0 20px' : '100px 0 20px 0')};
  }
  ${props =>
    props.flex &&
    `
    display: flex;
    @media (max-width: ${props.theme.deviceWidth.mobilePhone}) {
      flex-direction: column;
    }
  `}
  ${props =>
    props.pad &&
    `padding: 40px;
    @media ${props.theme.media.largePhone} {
      padding: 20px;
    }
  `};
`;

const CardContainer = ({
  children,
  className,
  flex = false,
  pad = false,
  pullUp = false,
  bgColor
}) => (
  <InnerWrapper>
    <CCWrapper
      className={className}
      flex={flex}
      pad={pad}
      pullUp={pullUp}
      bgColor={bgColor}
    >
      {children}
    </CCWrapper>
  </InnerWrapper>
);

CardContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  className: PropTypes.string,
  flex: PropTypes.bool,
  pad: PropTypes.bool,
  pullUp: PropTypes.bool,
  bgColor: PropTypes.string
};

export default CardContainer;
