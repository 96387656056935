/* eslint object-property-newline:0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import throttle from 'lodash.throttle';
import { withSiteData } from 'react-static';

import Error from '../Error';
import Footer from '../Footer';
import Logo from '../icons/Logo';
import MainNav from '../NavBar/MainNav';
import NavBar from '../NavBar';
import NavButton from '../NavBar/NavButton';
import SearchBarSwiftype from '../SearchBarSwiftype';

import { adminLinks } from '../../../mockData';

const DESKTOP_OPAQUE = 100;
const DESKTOP_OPAQUE_THRESHOLD = 1000;
const MOBILE_OPAQUE = 25;

const Content = styled.div`
  margin-top: ${props => (props.hasHero ? '0px' : '61px')};
  min-height: 200px;
`;

// prettier-ignore
const Wrapper = styled.section`
  background-color: ${p => p.bgColor ? p.theme.color[p.bgColor] : p.theme.color.bgPrimary};
  overflow: auto;

  ${p => p.overflowHidden && `
    overflow: hidden;
  `}
`;

const RightSideWrapper = styled.div`
  display: flex;

  @media ${p => p.theme.media.desktop} {
    display: none;
  }
`;

const scrollThreshold = () =>
  window.innerWidth < DESKTOP_OPAQUE_THRESHOLD ? MOBILE_OPAQUE : DESKTOP_OPAQUE;

class Page extends Component {
  state = {
    adminLinks,
    navIsTransparent: false
  };

  async componentDidMount() {
    if (typeof window !== 'undefined') {
      this.scrollListener = throttle(this.setNavTransparency, 100);
      window.addEventListener('scroll', this.scrollListener);
      this.setState({
        navIsTransparent:
          this.props.hasHero && window.scrollY < scrollThreshold()
      });
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.scrollListener);
    }
  }

  setNavTransparency = () => {
    if (!this.props.hasHero) return;

    if (typeof window !== 'undefined') {
      const { scrollY } = window;
      this.setState((state, props) => ({
        navIsTransparent: props.hasHero && scrollY < scrollThreshold()
      }));
    }
  };

  render() {
    const {
      children,
      className,
      error,
      forceOpaqueNav,
      hasHero,
      isAdmin,
      nav,
      navButton,
      overflowHidden,
      bgColor
    } = this.props;

    const { adminLinks, navIsTransparent } = this.state;

    return (
      <Wrapper
        className={className}
        overflowHidden={overflowHidden}
        bgColor={bgColor}
      >
        <NavBar transparent={!error && !forceOpaqueNav && navIsTransparent}>
          <Logo isAdmin={isAdmin} />
          <MainNav links={isAdmin ? adminLinks : nav} />
          <RightSideWrapper>
            {!isAdmin && <SearchBarSwiftype />}
            {!isAdmin && navButton && <NavButton navButton={navButton} />}
          </RightSideWrapper>
        </NavBar>
        <Content hasHero={!error && hasHero}>
          {error ? <Error message={error} /> : children}
        </Content>
        <Footer />
      </Wrapper>
    );
  }
}

Page.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  error: PropTypes.string,
  forceOpaqueNav: PropTypes.bool,
  hasHero: PropTypes.bool,
  isAdmin: PropTypes.bool,
  nav: PropTypes.array,
  navButton: PropTypes.shape({
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }),
  overflowHidden: PropTypes.bool,
  bgColor: PropTypes.string
};

export default withSiteData(Page);
