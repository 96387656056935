import React from 'react';
import styled from 'styled-components';
import { Redirect } from '@reach/router';
import Helmet from 'react-helmet';

import BlockTitle from '../../global/typography/BlockTitle';
import Button from '../../global/Button';
import CardContainer from '../../global/CardContainer';
import Disclaimer from '../../global/typography/Disclaimer';
import Page from '../../global/Page';
import TextField from '../../global/formFields/TextField';

import { isLoggedIn, login } from '../../../globalUtils/api';

const Form = styled.form`
  max-width: 450px;
  & > * {
    margin-bottom: 10px;
  }
`;

class Login extends React.PureComponent {
  state = {
    authenticated: isLoggedIn(),
    error: null,
    password: '',
    username: ''
  };

  login = async e => {
    e.preventDefault();
    this.setState({
      error: null
    });

    try {
      await login(this.state.username, this.state.password);
      this.setState({
        authenticated: true
      });
    } catch (err) {
      this.setState({
        error: `* ${err.message}`
      });
    }
  };

  updateProp = (prop, value) => {
    this.setState(state => ({
      [prop]: value
    }));
  };
  updateUsername = val => this.updateProp('username', val);
  updatePassword = val => this.updateProp('password', val);

  render() {
    return this.state.authenticated ? (
      <Redirect noThrow to="/admin" />
    ) : (
      <Page isAdmin>
        <Helmet title="Login" />
        <CardContainer pad>
          <BlockTitle>Admin Login</BlockTitle>
          <Form onSubmit={this.login}>
            <TextField
              change={this.updateUsername}
              required
              title="username"
              value={this.state.username}
            />
            <TextField
              change={this.updatePassword}
              required
              title="password"
              type="password"
              value={this.state.password}
            />
            <Button onClick={this.login}>Login</Button>
            <Disclaimer>{this.state.error}</Disclaimer>
          </Form>
        </CardContainer>
      </Page>
    );
  }
}

export default Login;
