import React from 'react';

import Icon from './Icon';

const Facebook = () => (
  <Icon href="https://www.facebook.com/LochinvarLLC" target="_blank">
    <svg width="36" height="36" xmlns="http://www.w3.org/2000/svg">
      <title id="facebookTitle">Facebook</title>
      <desc id="facebookDesc">The logo for Facebook</desc>
      <g>
        <path
          className="stroke"
          fill="none"
          stroke="#ffffff"
          strokeOpacity="0.75"
          d="m17.9999,0.5005c9.665207,0 17.5,7.834492 17.5,17.5c0,9.664207 -7.835492,17.5 -17.5,17.5c-9.664508,0 -17.5,-7.835793 -17.5,-17.5c0,-9.665508 7.834793,-17.5 17.5,-17.5z"
        />
        <path
          className="fill"
          fill="#ffffff"
          d="m18.9902,15.5854l0.003,-1.208c0,-0.628 0.055,-0.964 0.887,-0.964l1.111,0l0,-2.413l-1.778,0c-2.136,0 -2.889,1.17 -2.889,3.137l0,1.448l-1.332,0l0,2.411l1.332,0l0,6.998l2.666,0l0,-6.998l1.779,0l0.235,-2.411l-2.014,0z"
        />
      </g>
    </svg>
  </Icon>
);

export default Facebook;
