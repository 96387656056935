import React from 'react';

import Icon from './Icon';

const Twitter = () => (
  <Icon href="https://twitter.com/LochinvarLLC" target="_blank">
    <svg width="36" height="36" xmlns="http://www.w3.org/2000/svg">
      <title id="twitterTitle">Twitter</title>
      <desc id="twitterDesc">The logo for Twitter</desc>
      <g>
        <path
          className="stroke"
          fill="none"
          stroke="#ffffff"
          strokeOpacity="0.75"
          id="circle"
          d="m17.9999,0.5005c9.66521,0 17.5,7.83449 17.5,17.5c0,9.66421 -7.83549,17.5 -17.5,17.5c-9.66451,0 -17.5,-7.83579 -17.5,-17.5c0,-9.66551 7.83479,-17.5 17.5,-17.5z"
        />
        <path
          className="fill"
          fill="#ffffff"
          id="Fill-16"
          d="m25.166164,14.2983c-0.515,0.22 -1.069,0.37 -1.65,0.437c0.593,-0.344 1.049,-0.889 1.263,-1.538c-0.555,0.319 -1.169,0.551 -1.824,0.675c-0.523,-0.54 -1.27,-0.877 -2.096,-0.877c-1.587,0 -2.872,1.243 -2.872,2.778c0,0.217 0.025,0.43 0.074,0.633c-2.387,-0.116 -4.503,-1.222 -5.92,-2.902c-0.247,0.41 -0.389,0.887 -0.389,1.396c0,0.964 0.507,1.814 1.278,2.312c-0.471,-0.015 -0.914,-0.139 -1.301,-0.348l0,0.036c0,1.346 0.989,2.469 2.303,2.723c-0.241,0.063 -0.494,0.098 -0.756,0.098c-0.185,0 -0.365,-0.017 -0.54,-0.05c0.365,1.104 1.425,1.907 2.682,1.928c-0.982,0.746 -2.221,1.19 -3.567,1.19c-0.232,0 -0.46,-0.012 -0.685,-0.039c1.271,0.788 2.781,1.249 4.403,1.249c5.283,0 8.172,-4.233 8.172,-7.903c0,-0.122 -0.002,-0.241 -0.008,-0.361c0.561,-0.391 1.048,-0.88 1.433,-1.437"
        />
      </g>
    </svg>
  </Icon>
);

export default Twitter;
