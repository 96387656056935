import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import NavLink from '../../../../NavLink';
import Dropdown from '../Dropdown';
import { useClickedOutside } from '../../../../../../hooks/clickOutside';

const Underline = styled.div`
  background-color: transparent;
  margin-left: 4px;
  height: 3px;
  margin-top: 3px;
`;

const MainNavLinkWrapper = styled.li`
  color: ${props => props.theme.color.tertiary};
  display: ${props => (props.mobileOnly ? 'none' : 'inline-block')};
  font-family: ${props => props.theme.font.sourceSans};
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeight.semibold};
  list-style: none;
  position: relative;
  vertical-align: middle;

  & > .active {
    ${Underline} {
      background-color: ${props => props.theme.color.primary};
    }
  }

  &:hover {
    > ul {
      display: block;
    }
    ${Underline} {
      background-color: ${props => props.theme.color.primary};
    }
  }
  @media (max-width: 900px) {
    display: block;
  }
`;

const Link = styled.div`
  display: block;
  color: ${props => props.theme.color.tertiary};
  cursor: pointer;
  background-color: ${props => props.theme.color.transparent};
  height: 61px;
  padding: 22px 20px;
  text-decoration: none;
  transition: background-color 250ms ease-in-out;
`;

const LinkInner = styled.span`
  padding: 5px;
  margin-right: 2px;
  ${props =>
    props.hasSubNav &&
    `
    margin-right: 10px;
    position: relative;
    padding-right: 18px;
    border-bottom: 0 none;
    transition: border-bottom 250ms ease-in-out;
    &::before,
    &::after {
      background-color: ${props.theme.color.tertiary};
      content: '';
      position: absolute;
      width: 6px;
      height: 2.2px;
      top: 50%;
      border-radius: 50px;
      transition: transform 250ms ease-in-out;
    }
    &::before {
      transform: rotate(45deg);
      right: 2.9px;
    }
    &::after {
      transform: rotate(-45deg);
      right: 0;
    }
  `};
  ${props =>
    props.active &&
    `
    &::before {
          transform: rotate(-45deg);
        }
        &::after {
          transform: rotate(45deg);
        }`};
`;

const MainNavLink = ({
  mobileOnly,
  path,
  subNav,
  text,
  active,
  setActive,
  navRef
}) => {
  useClickedOutside(navRef, e => {
    // We don't want the dropdown to close if a user clicks any element inside the dropdown
    if (active && !e.target.closest('#nav-dropdown')) {
      setActive(null);
    }
  });

  const handleSetActive = text => {
    if (active) {
      setActive(null);
    } else {
      setActive(text);
    }
  };

  return (
    <MainNavLinkWrapper
      ref={navRef}
      mobileOnly={mobileOnly}
      hasSubNav={Boolean(subNav && subNav.length > 0)}
      onClick={() => handleSetActive(text)}
    >
      {path ? (
        <NavLink exact={path === '/'} to={path}>
          <Link>
            <LinkInner
              className="icon"
              hasSubNav={Boolean(subNav && subNav.length > 0)}
            >
              {text}
            </LinkInner>
            <Underline />
          </Link>
        </NavLink>
      ) : (
        <Link>
          <LinkInner
            active={active}
            className="icon"
            hasSubNav={Boolean(subNav && subNav.length > 0)}
          >
            {text}
          </LinkInner>
          <Underline />
        </Link>
      )}
      {subNav && subNav.length > 0 && (
        <Dropdown show={active} links={subNav} id="nav-dropdown" />
      )}
    </MainNavLinkWrapper>
  );
};

MainNavLink.propTypes = {
  mobileOnly: PropTypes.bool,
  path: PropTypes.string,
  text: PropTypes.string.isRequired,
  subNav: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      text: PropTypes.string.isRequired
    })
  ),
  active: PropTypes.bool,
  setActive: PropTypes.func,
  navRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
};

export default MainNavLink;
