import styled from 'styled-components';

export default styled.p`
  color: ${props => props.theme.color.textPrimary};
  font-size: 12px;
  font-family: ${props => props.theme.font.sourceSans};
  font-weight: ${props => props.theme.fontWeight.light};
  font-style: italic;
  line-height: 1.33;
  margin: 22px 0 42px 0;
`;
