import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormGroup from './FormGroup';
import Input from '../../global/formControls/Input';
import Label from '../../global/formControls/Label';

const ErrorMessage = styled.p`
  color: ${props => props.theme.color.primary};
  font-family: ${props => props.theme.font.sourceSans};
  margin-bottom: 0;
`;

const parseNumber = value => {
  if (value === '') return null;
  const parsed = Number(value);
  return Number.isNaN(parsed) ? value : parsed;
};

const TextField = ({
  blur,
  change,
  required,
  title,
  type = 'text',
  valid = true,
  value
}) => {
  /* Firefox allows non-numeric characters to be typed into type="number" fields,
   * but then it just reports an empty string on change/input events, meaning
   * there's no way to tell between invalid input and an empty field. This
   * sets type="number" fields to type="text", which isn't great UX but
   * it solves the issue.
   */
  const constrainedType = type === 'float' || type === 'int' ? 'text' : type;
  const formattedValue = value === 0 ? 0 : !value ? '' : value;
  return (
    <FormGroup>
      <Label>
        {title} {required && '*'}
      </Label>
      {typeof valid === 'string' && <ErrorMessage>{valid}</ErrorMessage>}
      <Input
        invalid={typeof valid === 'string' ? true : !valid}
        onBlur={e => {
          const handler = blur || change;
          return type === 'float' || type === 'int'
            ? handler(parseNumber(e.target.value))
            : handler(e.target.value);
        }}
        onChange={e =>
          type === 'float' || type === 'int'
            ? change(parseNumber(e.target.value))
            : change(e.target.value)
        }
        placeholder={`Enter ${title}`}
        type={constrainedType}
        value={formattedValue}
      />
    </FormGroup>
  );
};

TextField.propTypes = {
  blur: PropTypes.func,
  change: PropTypes.func.isRequired,
  required: PropTypes.bool,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'date',
    'email',
    'float',
    'int',
    'password',
    'tel',
    'text'
  ]),
  valid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default TextField;
