import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Heading } from './FooterHeading';
import NavLink from '../NavLink';

const Wrapper = styled.div`
  min-width: 230px;
`;

const StyledNavLink = styled(NavLink)`
  color: ${props => props.theme.color.linkPrimary};
  font-family: ${props => props.theme.font.montserrat};
  font-size: 13px;
  font-weight: ${props => props.theme.fontWeight.bold};
  text-decoration: none;
  letter-spacing: 0.82px;
  line-height: 2.77;

  &:after {
    padding-left: 10px;
    font-weight: 500;
    content: '>';
    display: inline-block;
    visibility: hidden;
  }

  transition: color 80ms ease-in-out;
  &:hover {
    text-decoration: underline;

    &:after {
      visibility: visible;
    }
  }
`;

const EmptyStyledNavLink = styled(StyledNavLink)`
  cursor: pointer;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  @media (max-width: ${props => props.theme.deviceWidth.mobilePhone}) {
    text-align: center;
  }
`;

const makeLinkFromData = (link, index) => (
  <li key={index}>
    {link.url ? (
      <StyledNavLink to={link.url} className={link.className}>
        {link.text}
      </StyledNavLink>
    ) : (
      <EmptyStyledNavLink className={link.className}>
        {link.text}
      </EmptyStyledNavLink>
    )}
  </li>
);

const LinkList = ({ links = [], heading }) => {
  const linkElements = links.map(makeLinkFromData);

  return (
    <Wrapper>
      <Heading>{heading}</Heading>
      <List>{linkElements}</List>
    </Wrapper>
  );
};

LinkList.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      text: PropTypes.string.isRequired
    })
  ),
  heading: PropTypes.string.isRequired
};

export default LinkList;
