import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import H1 from '../typography/H1';
import Disclaimer from '../typography/Disclaimer';

const Wrapper = styled.div`
  margin-top: 70px;
  width: 100%;
  text-align: center;
`;

const Error = ({ message }) => (
  <Wrapper>
    <H1>An Error Occured</H1>
    <Disclaimer>
      {message}
    </Disclaimer>
  </Wrapper>
);

Error.propTypes = {
  message: PropTypes.string
};

export default Error;
