import styled from 'styled-components';

const Input = styled.input`
  box-shadow: none;
  display: block;
  width: 100%;
  height: 40px;
  padding-left: 13px;

  border: none;
  border-bottom: 2px solid ${props => props.theme.color.borderPrimaryDarkened};
  border-radius: 0;
  background-color: ${props => props.theme.formControlBackgroundColor};
  outline: none;

  color: ${props => props.theme.color.textPrimary};
  font-size: 12px;
  line-height: 1.25;
  font-family: ${props => props.theme.font.montserrat};

  transition: border-color 120ms ease-in-out;

  &:invalid {
    background-color: ${props =>
    props.touched && props.theme.color.invalidBackground};
    border-color: ${props => props.touched && props.theme.color.primary};
  }

  &:focus {
    border-color: ${props => props.theme.color.primary};
  }

  &::placeholder {
    color: ${props => props.theme.color.placeholderText};
  }
`;

export default Input;
