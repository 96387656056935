import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const AppStoreImage = styled.img`
  margin-top: 1px;
  max-height: 39px;
`;

const GooglePlayImage = styled.img`
  max-height: 40px;
`;

const Image = styled.img`
  margin: 0 0 16px 0;
  max-width: 100%;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 230px;
`;

const LogosBlock = ({ image, appStore, googlePlay }) => (
  <Wrapper>
    <Image src={image.src} alt={image.alt} />
    <LogoWrapper>
      <a href={appStore.href} target="_blank" rel="noopener noreferrer">
        <AppStoreImage src={appStore.src} alt={appStore.alt} />
      </a>
      <a href={googlePlay.href} target="_blank" rel="noopener noreferrer">
        <GooglePlayImage src={googlePlay.src} alt={googlePlay.alt} />
      </a>
    </LogoWrapper>
  </Wrapper>
);

LogosBlock.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }),
  appStore: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }),
  googlePlay: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  })
};

export default LogosBlock;
