import React from 'react';

import Icon from './Icon';

const Youtube = () => (
  <Icon
    href="https://www.youtube.com/user/LochinvarCorporation"
    target="_blank"
  >
    <svg width="36" height="36" xmlns="http://www.w3.org/2000/svg">
      <title id="youtubeTitle">Youtube</title>
      <desc id="youtubeDesc">The logo for Youtube</desc>
      <g>
        <path
          className="stroke"
          fill="none"
          stroke="#ffffff"
          strokeOpacity="0.75"
          id="circle"
          d="m18,0.5c9.66521,0 17.5,7.83449 17.5,17.5c0,9.66421 -7.83549,17.5 -17.5,17.5c-9.66451,0 -17.5,-7.83579 -17.5,-17.5c0,-9.66551 7.83479,-17.5 17.5,-17.5z"
        />
        <path
          className="fill"
          fill="#ffffff"
          id="Page-1"
          d="m27,14.69173c0,-1.4864 -1.20427,-2.69173 -2.6896,-2.69173l-12.61973,0c-1.4864,0 -2.69067,1.20533 -2.69067,2.69173l0,7.21654c0,1.4864 1.20427,2.69173 2.6896,2.69173l12.61973,0c1.4864,0 2.69067,-1.20533 2.69067,-2.69173l0,-7.21654zm-10.8,6.65307l0,-6.7792l5.14053,3.3896l-5.14053,3.3896z"
        />
      </g>
    </svg>
  </Icon>
);

export default Youtube;
