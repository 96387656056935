import styled from 'styled-components';

export default styled.h1`
  color: ${props => props.theme.color.headerPrimary};
  font-size: 28px;
  font-family: ${props => props.theme.font.metaOt};
  font-weight: ${props => props.theme.fontWeight.bold};
  text-transform: uppercase;
  line-height: 1;
  margin: 0;
`;
