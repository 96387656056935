import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const Close = ({
  className,
  click = () => {},
  fill,
  noHover = false,
  size = 14
}) => (
  <Icon className={className} noHover={noHover} onClick={click}>
    <svg
      height={size}
      style={{ display: 'block' }}
      viewBox="0 0 14 14"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="fill" fill={fill || '#fff'}>
        <polygon points="14,1.3999996185302734 12.600006103515625,0 7,5.600000381469727 1.399993896484375,0 0,1.3999996185302734 5.600006103515625,7 0,12.599998474121094 1.399993896484375,14 7,8.400001525878906 12.600006103515625,14 14,12.599998474121094 8.399993896484375,7 " />
      </g>
    </svg>
  </Icon>
);

Close.propTypes = {
  className: PropTypes.string,
  click: PropTypes.func,
  fill: PropTypes.string,
  noHover: PropTypes.bool,
  size: PropTypes.number
};

export default Close;
