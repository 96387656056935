import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from './Icon';

const LogoWrapper = styled.div`
  flex: 1 0 auto;
  max-width: 200px;
`;

const Logo = ({ className, isAdmin }) => (
  <LogoWrapper className={`logo ${className}`}>
    <Icon href={isAdmin ? '/admin' : '/'}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384.2 70.6">
        <title id="logoTitle">Logo</title>
        <desc id="logoDesc">The logo for the Site</desc>
        <g>
          <path
            fill="#ffffff"
            d="M319.7,29.2h0.1V28H309v1.3c0.3,0,0.7,0,1,0.1l0,0c0.5,0.1,1,0.2,1.4,0.4l0.1,0c0.1,0.1,0.2,0.2,0.4,0.2c0.5,0.5,0.8,1.2,0.8,1.9c-0.2,1.9-0.6,3.7-1.4,5.5c-0.2,0.4-0.3,0.9-0.5,1.4l-0.2,0.4L306.4,50L301,37c-0.8-1.6-1.3-3.4-1.5-5.2c0-0.7,0.3-1.4,0.8-1.9c0.6-0.5,1.4-0.7,2.1-0.6h0.1v-1.3h-17.5v1.3c0.9,0.1,1.8,0.6,2.4,1.2c0.1,0.1,0.2,0.2,0.3,0.3l0.1,0.2l0.1,0.2l0.2,0.4l0.1,0.1l0.3,0.6v0.1l0.4,0.8l0,0c0.4,0.8,0.8,1.8,1.3,3l0,0l0.1,0.2l0.1,0.3l11,26.8v0.1l0,0l0,0h1.7l10.9-27.2c1.3-3.3,2.3-5.3,3-5.9C317.8,29.7,318.7,29.3,319.7,29.2z"
          />
          <path
            fill="#ffffff"
            d="M287.1,60c-0.5-0.7-0.8-2.3-0.8-5V41.4c0.1-2.8-0.1-5.6-0.6-8.3c-0.5-1.8-1.6-3.3-3-4.5c-0.6-0.5-1.3-0.9-2-1.2l0,0l-0.3-0.1h-0.1l-0.6-0.2h-0.1c-0.7-0.1-1.3-0.2-2-0.2h-0.2c-0.4,0-0.8,0-1.1,0.1h-0.1c-0.3,0-0.7,0.1-1,0.2l-0.3,0.1c-0.4,0.1-0.7,0.2-1.1,0.4h-0.1c-0.4,0.2-0.8,0.3-1.1,0.6c-1.8,1.1-3.5,2.6-4.8,4.2v-4.5h-13.4v1.3c1.1,0,2.1,0.5,2.9,1.3c0.5,0.7,0.7,2.3,0.7,5V55c0,2.7-0.2,4.3-0.6,4.8c-0.7,0.8-1.6,1.2-2.6,1.3c-0.9-0.1-1.8-0.4-2.4-1.1c-0.5-0.7-0.8-2.3-0.8-4.7V27.9h-13.8v1.3c1.2-0.1,2.3,0.4,3.2,1.2c0.5,0.7,0.8,2.3,0.8,4.7v20.2c0,2.5-0.2,4-0.7,4.6c-0.8,0.8-1.8,1.3-2.9,1.3c-1-0.1-1.9-0.5-2.5-1.2c-0.5-0.7-0.7-2.3-0.7-5V41.5c0.1-2.8-0.1-5.7-0.7-8.4c-0.5-1.7-1.5-3.2-2.9-4.4c-1.5-1.2-3.4-1.9-5.3-1.8c-1.6,0-3.2,0.4-4.7,1.2c-1.9,1.1-3.5,2.6-4.9,4.3v-20H203v1.3c1.1,0,2.2,0.5,2.9,1.3c0.5,0.7,0.7,2.3,0.7,5v35c0,2.7-0.2,4.3-0.6,4.8c-0.7,0.9-1.8,1.4-3,1.4v1.3h16.6v-1.3c-1.1-0.1-2.1-0.6-2.6-1.5c-0.4-0.6-0.5-2.2-0.5-4.7V36.5c0.7-1.3,1.7-2.5,2.8-3.5c0.8-0.6,1.7-1,2.7-1c0.6,0,1.2,0.2,1.7,0.6c0.5,0.4,0.9,1,1.1,1.7c0.3,1.8,0.4,3.6,0.3,5.4V55c0,2.5-0.2,4.1-0.6,4.8c-0.6,0.8-1.6,1.4-2.6,1.5v1.3h49.2v-1.3c-1,0-1.9-0.5-2.6-1.2c-0.5-0.7-0.7-2.3-0.7-4.9V36.5c1.7-3,3.6-4.5,5.6-4.5c0.6,0,1.2,0.2,1.7,0.6c0.1,0.1,0.2,0.2,0.4,0.3c0.3,0.4,0.6,0.9,0.7,1.4c0.2,1.1,0.3,2.3,0.3,3.4c0,0.6,0,1.2,0,1.9V55c0,2.5-0.2,4-0.6,4.6c-0.6,0.9-1.6,1.5-2.7,1.6v1.3H290v-1.3C288.9,61.2,287.9,60.8,287.1,60z"
          />
          <path
            fill="#ffffff"
            d="M347.7,57.8l-1.1-0.8c-0.6,0.9-1.2,1.4-1.9,1.4c-0.3,0-0.6-0.1-0.8-0.3l0,0c-0.3-0.2-0.4-0.6-0.5-0.9c-0.2-1-0.2-2-0.2-3V40.7c0.1-2.4-0.1-4.8-0.5-7.2c-0.7-1.8-1.9-3.4-3.6-4.5c-2.2-1.5-4.8-2.2-7.4-2.1c-2.5,0-5.1,0.4-7.5,1.3c-2.2,0.7-4.1,2-5.6,3.7c-1.3,1.4-2,3.2-2,5.1c0,1.3,0.5,2.5,1.5,3.3c1,0.9,2.3,1.4,3.7,1.4c1.3,0.1,2.5-0.4,3.4-1.3c0.8-0.8,1.3-1.9,1.3-3.1c-0.1-1.2-0.5-2.3-1.3-3.1c-0.5-0.5-0.9-1.2-1-1.9c0-0.6,0.4-1.2,0.9-1.6c1.1-0.8,2.4-1.1,3.8-1.1c0.9,0,1.8,0.3,2.5,0.8c0.7,0.4,1.2,1,1.6,1.8c0.4,1.6,0.5,3.3,0.4,5v3.5c-7.3,3.5-12,6.3-14.1,8.4c-2,1.8-3.2,4.4-3.3,7.2c-0.1,1.8,0.6,3.6,1.9,4.9c1.2,1.3,2.9,2,4.7,2c3.2,0,6.8-1.9,10.8-5.7c0.1,0.5,0.1,0.9,0.2,1.3c0.2,1.1,0.8,2.2,1.7,3c0.2,0.2,0.4,0.3,0.6,0.5c0.8,0.5,1.8,0.8,2.8,0.9c0.3,0,0.7,0.1,1,0.1c1.5,0,3-0.5,4.2-1.4C345.5,60.8,346.8,59.4,347.7,57.8zM326.9,55.8c-0.6-0.9-1-1.9-1.2-3c0-1.4,0.5-2.8,1.4-4c1.7-2.3,3.9-4.2,6.3-5.6v11.5c-1.5,1.5-2.9,2.2-4.1,2.2C328.4,56.9,327.5,56.5,326.9,55.8z"
          />
          <path
            fill="#ffffff"
            d="M168.6,29.2c-1.7-1.1-3.6-1.8-5.6-2.2h-0.1l-0.7-0.1h-0.4c-0.4,0-0.7,0-1.1,0c-4.4-0.2-8.6,1.9-11.2,5.5c-2.7,3.8-4.2,8.3-4.1,13c-0.1,4.6,1.3,9,4,12.7c0.3,0.4,0.6,0.8,1,1.2c0.1,0.1,0.2,0.2,0.4,0.4s0.5,0.5,0.7,0.7l0.4,0.4c0.2,0.2,0.5,0.4,0.7,0.5l0.5,0.3c0.3,0.2,0.5,0.3,0.8,0.5l0.5,0.2c0.3,0.2,0.7,0.3,1,0.4l0.4,0.2c0.5,0.2,1,0.3,1.5,0.4h0.3c0.4,0.1,0.7,0.1,1.1,0.2l0,0c0.7,0.1,1.4,0.1,2.1,0.1c4.7,0.2,9.2-2.1,11.8-6c2.5-3.6,3.7-8,3.5-12.3c0-3.2-0.6-6.4-1.9-9.4C173.1,33.2,171.2,30.8,168.6,29.2z M162.7,60.6c-0.6,0.3-1.3,0.5-2,0.5c-1.1,0-2.2-0.5-2.9-1.4c-0.9-1.1-1.5-2.5-1.6-4c-0.3-2.5-0.5-5-0.5-7.4c0-5,0.1-8.9,0.4-11.8s0.8-4.7,1.7-5.6c0.4-0.4,0.8-0.8,1.4-1.1c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0,0.2-0.1,0.3-0.1c0.3-0.1,0.6-0.1,0.9-0.1c0.9,0,1.7,0.2,2.4,0.8c1,0.9,1.7,2.1,2,3.5c0.6,2.9,0.8,5.8,0.7,8.7c0,7.2-0.2,11.9-0.7,14.3c-0.2,1.3-0.9,2.6-1.8,3.5C163.2,60.4,163,60.5,162.7,60.6z"
          />
          <path
            fill="#ffffff"
            d="M203.8,53.7c-1,1.3-2.3,2.3-3.7,3.2c-1,0.6-2.1,0.8-3.3,0.8c-1.7,0-3.3-0.5-4.6-1.6c-0.3-0.3-0.6-0.5-0.8-0.8l-0.2-0.2c-0.3-0.3-0.5-0.6-0.8-0.9c-1-1.4-1.8-3-2.3-4.7c-1-3-1.5-6.2-1.5-9.4c0-3.9,0.6-6.9,1.8-8.8c0.6-1.1,1.7-1.8,2.9-1.9c0.1,0,0.3,0,0.4,0h0.2l0.3,0.1l0.2,0.1c0.1,0.1,0.2,0.1,0.3,0.2L193,30c0.1,0.1,0.3,0.2,0.4,0.4c0.7,1,1,2.1,1,3.3c0.2,2.5,0.8,4.2,1.7,5.2c0.2,0.2,0.4,0.4,0.7,0.6l0.2,0.2c0.2,0.1,0.3,0.2,0.5,0.3l0.3,0.1c0.2,0.1,0.4,0.1,0.6,0.2l0.2,0h0.1l0,0c0.3,0,0.6,0.1,0.9,0.1c1.2,0.1,2.4-0.4,3.2-1.2c0.9-0.9,1.3-2.1,1.3-3.4c-0.1-2.3-1.2-4.5-3-6c-1.6-1.4-3.6-2.3-5.7-2.5l0,0c-0.8-0.1-1.6-0.2-2.4-0.2c-4.9-0.1-9.4,2.3-12,6.5c-2.5,3.7-3.8,8-3.8,12.5c0,1.8,0.2,3.5,0.6,5.2c0.7,2.7,1.9,5.2,3.5,7.3c0.3,0.4,0.6,0.8,1,1.2l0.3,0.3c0.2,0.2,0.5,0.5,0.7,0.7l0.4,0.3c0.2,0.2,0.5,0.4,0.7,0.5l0.5,0.3c0.2,0.2,0.5,0.3,0.7,0.4l0.5,0.2c0.3,0.1,0.5,0.2,0.8,0.3l0.5,0.2c0.3,0.1,0.6,0.2,0.9,0.3l0.4,0.1c0.5,0.1,0.9,0.2,1.4,0.2l0,0c0.5,0.1,1.1,0.1,1.6,0.1c2.6,0,5.2-0.8,7.4-2.2c2.5-1.7,4.5-4.1,5.8-6.8L203.8,53.7z"
          />
          <path
            fill="#ffffff"
            d="M246.7,22.7c1.5,0,2.9-0.6,3.9-1.7c1-1.1,1.6-2.5,1.6-4c0-1.5-0.5-3-1.6-4.1c-1-1.1-2.4-1.7-3.9-1.7c-1.5,0-2.9,0.6-3.8,1.7c-1,1.1-1.6,2.5-1.6,4c0,1.5,0.6,2.9,1.6,4C243.8,22.1,245.2,22.7,246.7,22.7z"
          />
          <path
            fill="#ffffff"
            d="M382.9,20.6c-1.7-1.7-4.4-1.7-6.1,0s-1.7,4.4,0,6.1c1.7,1.7,4.4,1.7,6.1,0c0,0,0,0,0,0c0.8-0.8,1.3-1.9,1.3-3C384.2,22.5,383.8,21.4,382.9,20.6z M382.5,26.2c-1.4,1.4-3.8,1.5-5.2,0c0,0,0,0,0,0c-0.7-0.7-1.1-1.7-1.1-2.6c0-2,1.7-3.7,3.7-3.6s3.7,1.7,3.6,3.7C383.6,24.7,383.2,25.6,382.5,26.2z"
          />
          <path
            fill="#ffffff"
            d="M381.8,25.6c0-0.1,0-0.2,0-0.4V25c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.3-0.5-0.4-0.8-0.5c0.2,0,0.5-0.1,0.7-0.2c0.3-0.2,0.5-0.6,0.5-0.9c0.1-0.5-0.2-1-0.7-1.2c-0.4-0.1-0.9-0.2-1.3-0.2h-1.6V26h0.8v-1.9h0.7c0.3,0,0.6,0,0.9,0.1c0.3,0.3,0.5,0.7,0.5,1.1v0.4v0.1V26h0.8v-0.1C381.8,25.8,381.8,25.7,381.8,25.6z M380.5,23.4c-0.3,0.1-0.5,0.1-0.8,0.1H379v-1.7h0.7c0.3,0,0.7,0,1,0.2c0.4,0.3,0.4,0.8,0.1,1.2C380.8,23.3,380.7,23.4,380.5,23.4z"
          />
          <path
            fill="#ffffff"
            d="M372.6,28.2c-0.8-0.9-2-1.3-3.1-1.3c-1.7,0.1-3.4,0.7-4.7,1.8c-1.6,1.2-3.5,3.6-5.7,7.2V28h-13.4v1.3c0.8,0,1.6,0.3,2.4,0.7c0.5,0.4,0.8,0.9,1,1.5c0.3,1.3,0.3,2.5,0.3,3.8v19.2c0,3-0.3,4.9-0.8,5.6c-0.7,0.8-1.8,1.3-2.9,1.2v1.3h17.8v-1.3c-1,0-1.9-0.2-2.8-0.6c-0.6-0.3-1-0.9-1.2-1.5c-0.2-0.9-0.3-1.8-0.2-2.7v-2.1v-8c0-2.7,0.3-5.4,1.1-7.9c0.4-1.4,1.1-2.6,2.1-3.6c0.5-0.5,1.2-0.8,1.9-0.8c0.3,0,0.6,0.1,0.8,0.2c0.2,0.1,0.7,0.6,1.6,1.4c0.9,0.8,2,1.3,3.2,1.2l0,0c1.1,0,2.1-0.5,2.7-1.4c0.8-1.1,1.2-2.5,1.1-3.8c0.1-1.2-0.3-2.5-1.1-3.5C372.7,28.2,372.6,28.2,372.6,28.2z"
          />
          <path
            fill="#ffffff"
            d="M139.6,53.9c-1,1.8-2.5,3.4-4.4,4.5c-1.9,1-4.1,1.5-6.3,1.4h-4c-1.4,0.1-2.8,0-4.2-0.3c-0.7-0.3-1.3-0.8-1.6-1.6c-0.4-1.6-0.5-3.3-0.4-4.9V21c-0.1-1.6,0-3.2,0.4-4.7c0.3-0.7,0.8-1.4,1.4-1.8c0.6-0.3,1.2-0.6,1.8-0.6h3.6v-1.4h-25.4v1.3h1.6c1.1-0.1,2.2,0.2,3.2,0.7c0.7,0.4,1.2,1,1.6,1.7c0.4,1.6,0.5,3.2,0.4,4.9v33c0.1,1.6,0,3.2-0.4,4.7c-0.2,0.7-0.8,1.4-1.4,1.8c-0.2,0.1-0.4,0.2-0.6,0.3l-0.3,0.1l-0.4,0.1l-0.4,0.1l-0.5,0.1h-0.3c-0.2,0-0.5,0-0.8,0h-1.6v1.3h42.4l1.8-17.7H143C142.2,48,141.1,51,139.6,53.9z"
          />
          <path
            fill="#ffffff"
            d="M35.2,6.5c1,1,2.5,2.5,6.4-0.2c1.1-0.8,3.1-1.8,4.1,0.2c0.5-0.2,1-0.3,1.5-0.2c-0.6-1.8-1.4-2-2-2.2H32.5C34.4,5.1,34.2,5.5,35.2,6.5z"
          />
          <path
            fill="#ffffff"
            d="M28.2,64c-0.2-0.2-0.2-0.2-0.7-1c-1.1-1.8-2.4-3.6-3.9-5.1c0.9-7.6,0-8.2-2-10.6c0.8,3.2-0.2,8.5-2,10.2c1.7,1.2,4.1,4.8,5.1,6.4c1.8,0.2,1.1,1.7,0.6,2.5s0,1,0.4,1.2l1.9,2.2c0.4-1.3,0.7-2.6,0.8-4V65C28.4,64.3,28.4,64.2,28.2,64z"
          />
          <path
            fill="#ffffff"
            d="M49.9,15.8c-0.6-0.3-1.1-0.8-0.4-1.3c0.9-0.6,1.6-1.4,2.1-2.3c0-0.2,0-0.4-0.1-0.5l-1.9-3.3c-0.9-1-3.2-2-4.8-0.2c-1.3,1.3-1.4,3.4-0.2,4.9c0.7,0.7,0.6,1.9-0.1,2.5c-0.3,0.3-0.7,0.5-1.2,0.5c-0.6,0-0.8-0.2-1.4,0.5c-0.7,0.7-4.8,5.5-5.3,6s-1.1,1.7-0.3,2.5s1.6,1.5,1.9,1.9s0.2,1-0.1,1H28c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h11.5c0.8,0.5,1.5,1.3,2,2.1c0.5,0,1,0,1.3,0.3c0.2-0.7,0.7-1.3,1.3-1.6V21H53C52.9,18.9,51.7,17,49.9,15.8z"
          />
          <path
            fill="#ffffff"
            d="M99.4,28.6c-0.4,0-18.7-0.5-18.7-0.5c0-0.6-0.3-1.1-0.8-1.4c-0.5-0.3-5.6-4.9-5.6-4.9c-0.1-0.4-0.1-0.9-0.1-1.3c0-0.4-0.5-0.7-0.5-0.7l-3.1-2.6l-0.2-3.5l-1.8,1.7c0.5-3.2,1.2-9.1,1.3-11.7v-1l-0.9-0.2C60.4,0.8,51.8,0,43.2,0c-8.6,0-17.3,0.9-25.8,2.5l-0.9,0.2l0,1c0.4,10,2.5,19.9,6.1,29.3c-0.9,0.6-1.6,1.3-2.3,1.9H0c1.1,0.3,2.2,0.9,3,1.8c3.8,4.9,9.8,2.3,12.6,1s2.5-0.7,3.6-0.2c0.2,2.3,1.9,7.2,4,9.2c2.9,2.8,3.5,7.5,2.5,11c2,1.1,7.9,7,7.9,7c2.1-0.3,0.8,2.2,0.6,2.6s0.1,0.7,0.3,0.8l2.4,2.7c0.2-0.4,0.3-0.8,0.3-1.2c0-0.3,0.2-2.3,0.2-3.3s-0.2-1.5-0.4-1.7c-0.5-0.4-0.9-0.8-1.3-1.3c-0.6-0.7-5.1-5.5-6.6-7.1c-0.2-1.2,0.7-3.5,2-5c3.3,5.5,6.9,10.8,11,15.8l0.8,1l0.8-1c4-4.9,7.7-10,11-15.4c0.3,0.1,0.7,0.2,1,0.3c3.1,1.2,6.8,2.9,12.2,2.8C67.4,57,65.5,59,63,59.7c-0.1-0.7-0.1-1.4-0.1-2.1c-0.8,0.7-1.9,1.2-3,1.4c0,1.1-0.1,2.9-0.4,3.3c1.3-0.4,2.8-0.5,4.1-0.3c2.2-1.3,5.9-3.7,6.9-4.1c0.2-0.2,0.3-0.5,0.3-0.8c0.3-1.1,0.5-2.3,0.6-3.5c1.5,0.1,3-0.1,4.4-0.6c1.1,1.1,2.2,2.1,3.4,3c0.8,0.5,1.4,1.4,1.4,2.3c0,0.9-0.2,1.7-0.3,2.6c-0.1,0.4-0.2,0.8-0.2,1.2c0.1,0.4,0.4,0.6,0.8,0.8c1.4,0.8,2.7,1.7,4,2.7c0.4,0.3,0.3-0.2,0.3-0.2c-0.1-0.7-0.4-1.4-0.8-2c-1.1-1.5-1.7-3.3-1.8-5.2c-0.1-0.6-0.2-1.1-0.5-1.6c0,0-2.7-4.3-3.5-6s-0.6-1.5-2.7-1.6c-2.1-0.2-4.2-0.4-6.2-0.8c0.2-3.5-0.2-7-1.3-10.3c0.5-2.9,0.4-5.8-0.2-8.7H75c1.2,0.6,2,2,4.3,0.8c0.7-0.3,1.5-0.8,1.5-0.8L99.5,29C99.9,29,99.8,28.6,99.4,28.6z M56.5,27.5v-6.7L44.2,34.5l6,8.4l1.8-1.7c0.3-0.2,1.7-0.5,1,0.6s-3.8,5.7-4.5,7.6c0,0-0.5,1.5,1.2,3.3c1-0.4,1.7-1,2.6-1.3c-2.8,4.5-5.8,8.7-9.1,12.8c-3.8-4.8-7.3-9.8-10.4-15.1c3-3.5,2.7-4,3.2-6.5c1.3,1,1.4,3.3,0.5,5c2.8-0.1,5.6,0.4,8.3,1.3c0.3-2,1.4-3.8,3.1-5c0,0-7.3-10.7-7.9-11.3c-0.4-0.6-1.1-0.9-1.8-0.8c-1.1,0-6.2-0.3-7.9-0.6c-1.8-0.3-3.7-0.2-5.5,0.4c-2-5.9-3.6-11.9-4.8-18c-0.6-3.1-1-6.2-1.2-9.3C26.8,3,35,2.3,43.2,2.2c8.2,0,16.3,0.8,24.4,2.2c-0.2,2.9-0.9,8.1-1.6,11.9C63.7,16.9,60.8,18.9,56.5,27.5z"
          />
        </g>
      </svg>
    </Icon>
  </LogoWrapper>
);

Logo.propTypes = {
  className: PropTypes.string,
  isAdmin: PropTypes.bool
};

export default Logo;
