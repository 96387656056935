// Imports


// Plugins
const plugins = [{
  location: "/var/task/node_modules/react-static-plugin-styled-components",
  plugins: [],
  hooks: {}
},
{
  location: "/var/task",
  plugins: [],
  hooks: {}
}]

// Export em!
export default plugins