import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const Menu = ({ onClick }) => (
  <Icon onClick={onClick}>
    <svg width="18" height="12" xmlns="http://www.w3.org/2000/svg">
      <title>Menu</title>
      <desc>Menu Icon</desc>
      <g className="fill" fill="#fff">
        <path d="m0,12l18,0l0,-2l-18,0l0,2l0,0zm0,-5l18,0l0,-2l-18,0l0,2l0,0zm0,-7l0,2l18,0l0,-2l-18,0l0,0z" />
      </g>
    </svg>
  </Icon>
);

Menu.propTypes = {
  onClick: PropTypes.func
};

export default Menu;
