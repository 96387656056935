export default [
  {
    availableSeats: 0,
    end: (new Date()).toString(),
    id: 1,
    start: (new Date()).toString(),
    title: 'Commercial Water Heater Sizing School'
  },
  {
    availableSeats: 5,
    end: (new Date()).toString(),
    id: 2,
    start: (new Date()).toString(),
    title: 'Residential Water Heater Sizing School'
  }
];
