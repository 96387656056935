export default [
  {
    path: '/admin/careers',
    text: 'Careers'
  },
  {
    path: '/admin/contact-departments',
    text: 'Contact Us'
  },
  {
    path: '/admin/articles',
    text: 'Articles'
  },
  {
    path: '/admin/about',
    subNav: [
      {
        path: '/admin/about',
        text: 'About'
      },
      {
        path: '/admin/careers-page',
        text: 'Careers'
      },
      {
        path: '/admin/news',
        text: 'News'
      },
      {
        path: '/admin/training',
        text: 'Training'
      }
    ],
    text: 'Pages'
  },
  {
    path: '/admin/products',
    subNav: [
      {
        path: '/admin/products',
        text: 'Products'
      },
      {
        path: '/admin/product-categories',
        text: 'Categories'
      },
      {
        path: '/admin/literature-categories',
        text: 'Literature Categories'
      }
    ],
    text: 'Products'
  },
  {
    path: '/admin/filters',
    text: 'WTB',
    subNav: [
      {
        path: '/admin/sales-reps',
        text: 'Sales Reps'
      },
      {
        path: '/admin/distributors',
        text: 'Distributors'
      },
      {
        path: '/admin/filters',
        text: 'Filters'
      },
      {
        path: '/admin/intl-locator',
        text: 'Intl. Locator'
      }
    ]
  },
  {
    path: '/admin/schools',
    subNav: [
      {
        path: '/admin/schools',
        text: 'Schools'
      },
      {
        path: '/admin/registration',
        text: 'Registration Search'
      }
    ],
    text: 'University'
  },
  {
    path: '/admin/users',
    text: 'Users'
  },
  {
    path: '/admin/uploads',
    text: 'Uploads'
  },
  {
    path: '/logout',
    text: 'Logout'
  }
];
