import React from 'react';
import PropTypes from 'prop-types';

const dirToRotation = direction => {
  switch (direction) {
    case 'down':
      return 180;
    case 'left':
      return 270;
    case 'right':
      return 90;
    default:
      return 0;
  }
};

const Arrow = ({
  className,
  direction = 'right',
  fill = '#000000',
  onClick,
  size = 10
}) => (
  <svg
    className={className}
    onClick={onClick}
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: `rotate(${dirToRotation(direction)}deg)` }}
    viewBox="0 0 10 10"
  >
    <g className="fill" fill={fill}>
      <path d="m8.70699,6.79833c-0.39099,0.32584 -1.02399,0.32584 -1.414,0l-2.29399,-1.91083l-2.292,1.91083c-0.39101,0.32584 -1.02401,0.32584 -1.414,0c-0.391,-0.325 -0.391,-0.8525 0,-1.17833l2.99899,-2.5c0.187,-0.15667 0.44101,-0.24417 0.70701,-0.24417c0.266,0 0.519,0.0875 0.707,0.24417l3.00099,2.5c0.391,0.325 0.391,0.8525 0,1.17833" />
    </g>
  </svg>
);

Arrow.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  fill: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.number
};

export default Arrow;
