export default [
  {
    availableSeats: 0,
    end: new Date().toString(),
    id: 1,
    schoolId: 'school-1',
    start: new Date().toString(),
    title: 'Commercial Water Heater Sizing School'
  },
  {
    availableSeats: 1,
    end: new Date().toString(),
    id: 2,
    schoolId: 'school-2',
    start: new Date().toString(),
    title: 'Commercial Non-Condensing Products School'
  },
  {
    availableSeats: 5,
    end: new Date().toString(),
    id: 3,
    schoolId: 'school-3',
    start: new Date().toString(),
    title: 'Residential Condensing Boiler Products School'
  },
  {
    availableSeats: 2,
    end: new Date().toString(),
    id: 4,
    schoolId: 'school-4',
    start: new Date().toString(),
    title: 'Residential Water Heater Products School'
  }
];
