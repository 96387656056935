import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Arrow from '../../../../icons/Arrow';

const CategoryText = styled.span`color: ${props => props.theme.color.primary};`;

const InText = styled.span`
  color: ${props => props.theme.color.tertiary};
  font-weight: ${props => props.theme.fontWeight.extralight};
`;

const NavItem = styled.li`
  border-bottom: 1px solid ${props => props.theme.color.borderPrimaryOverlay};
  font-family: ${props => props.theme.font.sourceSans};
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeight.normal};
  padding: 11px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const ResultText = styled.span`color: ${props => props.theme.color.tertiary};`;

const MobileSearchLink = ({ result, onResultClick }) => (
  <NavItem onClick={() => onResultClick(result)}>
    <span>
      <ResultText>{result.suggestion}</ResultText>&nbsp;
      {result.category && <InText>in</InText>}&nbsp;
      <CategoryText>{result.category}</CategoryText>
    </span>
    <Arrow direction="right" fill="#fff" />
  </NavItem>
);

MobileSearchLink.propTypes = {
  onResultClick: PropTypes.func.isRequired,
  result: PropTypes.shape({
    category: PropTypes.string,
    path: PropTypes.string.isRequired,
    suggestion: PropTypes.string.isRequired
  }).isRequired
};

export default MobileSearchLink;
