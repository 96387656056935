import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Arrow from '../../../icons/Arrow';
import Close from '../../../icons/Close';
import Icon from '../../../icons/Icon';
import Logo from '../../../icons/Logo';
import MobileNavLink from './MobileNavLink';
import MobileSearchLink from './MobileSearchLink';
import NavBar from '../../index';

import { navigateToOption } from '../../../formControls/Search/utils';

const Header = styled(NavBar)`
  background: ${props => props.theme.color.headerPrimary};
  .logo {
    a {
      padding-right: 0;
      padding-left: 0;
    }
  }
  padding: 0 20px 0 12px;
`;

const MobileLogo = styled(Logo)`
  display: flex;
`;

const NavList = styled.ul`
  list-style: none;
  margin-top: ${props => (props.addTopMargin ? '62px' : '0')};
  padding: 0;
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background: ${props => props.theme.color.bgSecondary};
`;

const BackLink = styled.div`
  background-color: ${props => props.theme.color.primary};
  padding: 18px 36px;
  width: 100%;
  position: fixed;
  bottom: 0;

  a {
    display: flex;
    align-items: center;
  }

  span {
    color: ${props => props.theme.color.tertiary};
    font-family: ${props => props.theme.font.sourceSans};
    font-size: 18px;
    font-weight: ${props => props.theme.fontWeight.bold};
    display: block;
    text-transform: uppercase;
    margin-left: 8px;
  }
`;

const NavHeading = styled.li`
  width: 100%;
  padding: 0 20px;
`;

const NavHeadingText = styled.span`
  font-family: ${props => props.theme.font.sourceSans};
  font-size: 18px;
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.color.tertiary};
  display: block;
  padding: 20px 0;
  border-bottom: 1px solid ${props => props.theme.color.borderPrimaryOverlay};
  text-transform: uppercase;
`;

class MobileNav extends React.Component {
  state = {
    links: this.props.links || [],
    parents: [],
    suggestions: [],
    menuHeading: '',
    parentMenu: null
  };

  componentWillReceiveProps(props) {
    this.setState({
      links: props.links || [],
      parents: []
    });
  }

  close = () => {
    this.setState({
      parents: []
    });
    this.props.close();
  };

  navigateToMenu = menu => {
    this.setState(state => ({
      links: menu.subNav,
      parents: [...state.parents, state.links],
      menuHeading: menu.text,
      // stores the previous menu heading in state so we can use it if the user hits back from the 2nd level menu
      parentMenu: state.parents.length === 1 && state.menuHeading
    }));
  };

  navigateUp = () => {
    this.setState(state => ({
      links:
        state.parents.length > 0
          ? state.parents[state.parents.length - 1]
          : this.props.links,
      parents: state.parents.slice(0, -1),
      menuHeading: state.parents.length === 2 ? state.parentMenu : ''
    }));
  };

  render() {
    return (
      <Wrapper className={this.props.className}>
        <Header>
          <MobileLogo />
          <Close click={this.close} />
        </Header>

        <NavList addTopMargin={!this.props.showSearch}>
          {this.state.menuHeading && (
            <NavHeading>
              <NavHeadingText>{this.state.menuHeading}</NavHeadingText>
            </NavHeading>
          )}
          {
            // prettier-ignore
            this.props.showSearch
              ? this.state.suggestions.map((suggestion, index) => (
                <MobileSearchLink
                  key={index}
                  onResultClick={navigateToOption}
                  result={suggestion}
                />
              ))
              : this.state.links.map((link, index) => (
                <MobileNavLink
                  close={this.close}
                  link={link}
                  key={index}
                  navigateToMenu={this.navigateToMenu}
                  isChild={Boolean(this.state.menuHeading)}
                />
              ))
          }
        </NavList>
        {this.state.parents.length > 0 && (
          <BackLink onClick={this.navigateUp}>
            <Icon>
              <Arrow direction="left" fill="#fff" size={20} />
              <span>
                Back{this.state.parents.length === 1 && ' to Main Menu'}
              </span>
            </Icon>
          </BackLink>
        )}
      </Wrapper>
    );
  }
}

MobileNav.propTypes = {
  className: PropTypes.string,
  close: PropTypes.func,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      text: PropTypes.string.isRequired,
      subNav: PropTypes.array
    })
  ),
  showSearch: PropTypes.bool
};

export default MobileNav;
