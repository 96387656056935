import styled from 'styled-components';

export const Heading = styled.h3`
  color: ${props => props.theme.color.footerHeading};
  font-family: ${props => props.theme.font.montserrat};
  font-size: 10px;
  font-weight: ${props => props.theme.fontWeight.bold};
  letter-spacing: 0.58px;
  line-height: 1.3;
  text-transform: uppercase;
  margin: 0 0 10px 0;

  @media (max-width: ${props => props.theme.deviceWidth.mobilePhone}) {
    text-align: center;
  }
`;
