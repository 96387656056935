import styled from 'styled-components';

const NavBar = styled.div`
  background-color: ${props =>
    props.transparent
      ? props.theme.color.transparent
      : props.theme.color.borderPrimaryOverlay};
  transition: background-color 120ms ease-in-out;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  height: 61px;
  padding: 0 20px;
  .logo {
    a {
      display: block;
      padding-right: 20px;
    }
    svg {
      max-width: 157px;
      max-height: 29px;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  @media (max-width: ${props => props.theme.deviceWidth.mobilePhone}) {
    .logo {
      a {
        padding: 16px 0;
      }
      svg {
        max-width: 127px;
      }
    }
  }
  @media print {
    display: none;
  }
`;

export default NavBar;
