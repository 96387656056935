import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const Search = ({
  fill = '#ffffff',
  width = 13,
  height = 13,
  onClick,
  className = ''
}) => (
  <Icon onClick={onClick} className={className}>
    <svg
      className="search-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13 13"
      width={width}
      height={height}
    >
      <title id="logoTitle">Search</title>
      <desc id="logoDesc">Search Icon for the Search Bar</desc>
      <g>
        <path
          className="fill"
          fill={fill}
          d="M5.7,9.8c-2.2,0-4.1-1.8-4.1-4.1s1.8-4.1,4.1-4.1s4.1,1.8,4.1,4.1S7.9,9.8,5.7,9.8L5.7,9.8z M10.2,9.1c0.7-1,1.1-2.1,1.1-3.4C11.4,2.6,8.8,0,5.7,0S0,2.6,0,5.7s2.6,5.7,5.7,5.7c1.3,0,2.5-0.4,3.4-1.1l2.5,2.5c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.3-0.3,0.3-0.8,0-1.2L10.2,9.1z"
        />
      </g>
    </svg>
  </Icon>
);

Search.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default Search;
