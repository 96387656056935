import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Menu from '../../icons/Menu';
import MobileNav from './MobileNav';
import Nav from './Nav';
import Search from '../../icons/Search';
import showSwiftype from '../../SearchBarSwiftype/utils';

const MobileWrapper = styled.div`
  display: none;
  @media ${p => p.theme.media.desktop} {
    display: block;
  }
  & > a {
    padding: 22px 20px;
  }
  flex-wrap: nowrap;
  @media (max-width: ${props => props.theme.deviceWidth.mobilePhone}) {
    & > a {
      padding: 22px 14px;
    }
    margin-right: -14px;
  }
`;

const NavWrapper = styled.div`
  flex: 0 1 auto;
`;

const ResponsiveMobileNav = styled(MobileNav)`
  display: none;
  @media ${p => p.theme.media.desktop} {
    display: block;
  }
`;

const ResponsiveNav = styled(Nav)`
  display: block;
  @media ${p => p.theme.media.desktop} {
    display: none;
  }
`;

class MainNav extends React.Component {
  state = {
    showMenuMobile: false,
    showSearch: false
  };

  updateDimensions = () => {
    if (this.state.showMenuMobile && window.innerWidth > 1240) {
      this.setState({ showMenuMobile: false });
      document.body.style.overflow = 'visible';
    }
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      document.body.style.overflow = 'visible';
      window.addEventListener('resize', this.updateDimensions);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  toggleMenu = () => {
    this.setState(
      state => ({
        showMenuMobile: !state.showMenuMobile,
        showSearch: false
      }),
      () => {
        if (typeof window !== 'undefined') {
          this.state.showMenuMobile
            ? (document.body.style.overflow = 'hidden')
            : (document.body.style.overflow = 'visible');
        }
      }
    );
  };

  toggleSearch = () => {
    this.setState(
      state => ({
        showMenuMobile: !state.showMenuMobile,
        showSearch: true
      }),
      () => {
        if (typeof window !== 'undefined') {
          this.state.showMenuMobile
            ? (document.body.style.overflow = 'hidden')
            : (document.body.style.overflow = 'visible');
        }
      }
    );
  };

  render() {
    return (
      <NavWrapper>
        {this.state.showMenuMobile && (
          <ResponsiveMobileNav
            close={this.toggleMenu}
            links={this.props.links}
            showSearch={this.state.showSearch}
          />
        )}
        <MobileWrapper>
          <Search onClick={showSwiftype} />
          <Menu onClick={this.toggleMenu} />
        </MobileWrapper>
        <ResponsiveNav links={this.props.links} />
      </NavWrapper>
    );
  }
}

MainNav.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      text: PropTypes.string.isRequired,
      subNav: PropTypes.array
    })
  ).isRequired
};

export default MainNav;
