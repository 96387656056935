import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: rgba(33, 33, 33, 0.5);
  color: ${props => props.theme.color.textLight};
  font-family: ${props => props.theme.font.montserrat};
  font-weight: ${props => props.theme.fontWeight.bold};
  letter-spacing: 0.36px;
  line-height: 1.3;
  padding: 22px 9% 22px;

  @media (max-width: ${props => props.theme.deviceWidth.mobilePhone}) {
    text-align: center;
  }
`;

const FooterCopyright = () => (
  <Wrapper>
    © Lochinvar {(new Date()).getFullYear()} - All Rights Reserved
  </Wrapper>
);

export default FooterCopyright;
