import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DropdownLink from './Link';

const FlexWrapper = styled.div`
  display: flex;
`;

/**
 * This wrapper exists because IE 11 didn't like a flex container being at the
 * top level of this DOM structure. This wrapper was added so that it would
 * render correctly.
 */
const OuterWrapper = styled.div`
  position: absolute;
  display: ${p => (p.show ? 'block' : 'none')};
  background-color: ${p => p.theme.color.bgSecondary};
  min-width: 100%;
  padding: 6px 0;
`;

const Parents = styled.div`
  flex: 1 0 auto;
`;

class Dropdown extends React.PureComponent {
  state = {
    activePath: '',
    subLinks: []
  };

  changeHovered = link =>
    this.setState({ activePath: link.path, subLinks: link.subNav || [] });
  clearHovered = () => this.setState({ activePath: '', subLinks: [] });

  render() {
    const { links, show, id } = this.props;
    const { activePath, subLinks } = this.state;

    return (
      <OuterWrapper show={show} onMouseLeave={this.clearHovered} id={id}>
        <FlexWrapper>
          <Parents>
            {links.map(link => (
              <DropdownLink
                active={activePath === link.path}
                key={link.path}
                link={link}
                onHover={this.changeHovered}
              />
            ))}
          </Parents>
          <div>
            {subLinks.map(link => (
              <DropdownLink key={link.path} link={link} sub />
            ))}
          </div>
        </FlexWrapper>
      </OuterWrapper>
    );
  }
}

Dropdown.propTypes = {
  links: PropTypes.array,
  show: PropTypes.bool,
  id: PropTypes.string
};

export default Dropdown;
