import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import styled from 'styled-components';

const StyledAnchor = styled.a`
  text-decoration: none;
  color: inherit;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const NavLink = ({ children, className, exact = true, to }) => {
  // if there is no url give, return anchor with no href
  if (to === undefined) {
    return <StyledAnchor className={className}>{children}</StyledAnchor>;
  }

  // use anchor for external links
  if ((to || '').indexOf('.') > -1) {
    return (
      <StyledAnchor href={to} className={className} target="_blank">
        {children}
      </StyledAnchor>
    );
  }

  // use react router Link for internal links
  return (
    <StyledLink
      className={className}
      to={/^\//.test(to) ? to : exact ? `/${to}` : to}
    >
      {children}
    </StyledLink>
  );
};

NavLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    )
  ]).isRequired,
  className: PropTypes.string,
  exact: PropTypes.bool,
  to: PropTypes.string
};

export default NavLink;
