import React from 'react';
import styled from 'styled-components';

import Logo from '../icons/Logo';

const Description = styled.p`
  color: ${props => props.theme.color.textLight};
  font-size: 11px;
  font-family: ${props => props.theme.font.montserrat};
  font-weight: ${props => props.theme.fontWeight.light};
  line-height: 1.55;
  letter-spacing: 0.39px;
  margin: 11px 0 0 0;

  @media ${props => props.theme.media.mobilePhone} {
    display: none;
  }
`;

const Wrapper = styled.div`
  max-width: 275px;

  @media ${props => props.theme.media.mobilePhone} {
    display: flex;
    justify-content: center;
    min-width: 250px;
  }
`;

const BrandStatement = () => (
  <Wrapper>
    <Logo />
    <Description>
      We are a leading producer of energy-efficient water heating solutions that
      are radically simple, brilliantly engineered and perfectly suited for most
      any application. We focus solely on serving the unique needs of each
      customer with a product portfolio including boilers, water heaters, pool
      heaters, cogeneration products and commercial package systems.
    </Description>
  </Wrapper>
);

export default BrandStatement;
