import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BaseButton from '../../Button';
import BaseNavLink from '../../NavLink';

const Button = styled(BaseButton)`
  margin-left: 24px;
  min-width: unset;
  padding: 12px;
  width: 120px;
`;

const NavLink = styled(BaseNavLink)`
  @media ${p => p.theme.media.desktop} {
    display: none;
  }
`;

const NavButton = ({ navButton }) => (
  <NavLink to={navButton.path} exact>
    <Button>{navButton.text}</Button>
  </NavLink>
);

NavButton.propTypes = {
  navButton: PropTypes.shape({
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired
};

export default NavButton;
