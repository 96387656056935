import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @font-face {
    font-family: "Merriweather Sans";
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/MerriweatherSans-Bold.ttf') format('ttf');
  }

  @font-face {
    font-family: "Merriweather Sans";
    font-style: normal;
    font-weight: 900;
    src: url('/fonts/MerriweatherSans-ExtraBold.ttf') format('ttf');
  }

  html {
    box-sizing: border-box;
    font-size: 12px;
  }
  body {
    font-size: 12px;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
`;
