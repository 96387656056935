import React from 'react';
import PropTypes from 'prop-types';

const ExternalArrow = ({ className, fill = '#ffffff', onClick, size = 18 }) => (
  <svg
    className={className}
    onClick={onClick}
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
  >
    <g className="fill" fill={fill}>
      <path
        d="M9.716 3.938a.563.563 0 0 0-.563-.563H1.687A1.687 1.687 0 0 0 0 5.063v11.25A1.687 1.687 0 0 0 1.688 18h11.25a1.687 1.687 0 0 0 1.687-1.688V8.848a.563.563 0 0 0-1.125 0v7.466a.562.562 0 0 1-.563.562H1.688a.563.563 0 0 1-.562-.563V5.063a.563.563 0 0 1 .563-.562h7.465a.563.563 0 0 0 .563-.563Z"
        fill={fill}
      />
      <path
        d="M18 .563A.563.563 0 0 0 17.437 0h-5.625a.563.563 0 1 0 0 1.125h4.268l-9.166 9.164a.563.563 0 0 0 .797.797l9.164-9.166v4.268a.563.563 0 0 0 1.125 0V.563Z"
        fill={fill}
      />
    </g>
  </svg>
);

ExternalArrow.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.number
};

export default ExternalArrow;
