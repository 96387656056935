import axios from 'axios';
import Cookie from 'js-cookie';

const calculateBase = (env = process.env.LOCH_ENV) => {
  switch (env) {
    case 'production':
      return 'https://api.lochinvar.com';
    case 'staging':
      return 'https://api.stage.lochinvar.com';
    case 'development':
      return 'https://api.dev.lochinvar.com';
    case 'local':
    default:
      return 'http://localhost:8080';
  }
};

export const base = calculateBase();
const apiBase = `${base}/api`;
// Required to send session to server
axios.defaults.withCredentials = true;

/**
 * Client-side helper to try and determine if the user is logged in
 * @return {Boolean} If the user seems to be logged in
 */
export const isLoggedIn = () => Boolean(Cookie.get('connect.sid'));

/**
 * Use either JSON or form data to submit data based on what is needed
 * @param {String} query       The GraphQL query
 * @param {Object} variables   The variables being used by this query
 * @param {Object} attachments Contains information about the attached files
 * @param {String} attachments.field The field the files are using
 * @param {File[]} attachments.files The files themselves
 */
const setUpQueryBody = (query, variables, attachments = {}) => {
  if (Object.keys(attachments).length > 0) {
    const formData = new FormData();
    formData.append('query', query);
    formData.append('variables', JSON.stringify(variables));

    const appendAttachments = (formData, attachments, prevKeys = []) => {
      Object.keys(attachments).forEach(key => {
        if (attachments[key] instanceof Blob) {
          return formData.append(
            // serialize the path to the field with the file as a dot-delimited string
            prevKeys.concat(key).join('.'),
            attachments[key],
            attachments[key].name
          );
        }

        return appendAttachments(
          formData,
          attachments[key],
          prevKeys.concat(key)
        );
      });
    };

    appendAttachments(formData, attachments);
    return formData;
  }

  return { query, variables };
};

export const query = async (
  query,
  { variables, attachments, env = process.env.LOCH_ENV } = {}
) => {
  try {
    const response = await axios(`${calculateBase(env)}/graphql`, {
      data: setUpQueryBody(query, variables, attachments),
      method: 'POST'
    });
    if (response.data.errors) {
      return response.data;
    }

    return response.data.data;
  } catch (e) {
    if (e.response == null) {
      console.log(e);
    } else {
      console.log(
        e.response.data.errors ? e.response.data.errors : e.response.data
      );
    }
    return null;
  }
};

export const login = (username, password) =>
  axios.post(`${apiBase}/login`, { password, username }).then(resp => {
    if (resp.error) {
      throw new Error(resp.error.message || 'Invalid username or password');
    }
  });

export const logout = (withPost = true) => {
  if (typeof document !== 'undefined') {
    const remove = Cookie.remove.bind(this, 'connect.sid');
    const { LOCH_ENV: env } = process.env;

    switch (env) {
      case 'production':
      case 'staging':
      case 'development':
        remove({ domain: '.lochinvar.com' });
        break;
      default:
        remove();
    }
  }
  if (withPost) {
    return axios.post(`${apiBase}/logout`);
  }
};

export default query;
