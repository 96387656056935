import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Arrow from '../../../../icons/Arrow';
import ExternalArrow from '../../../../icons/ExternalArrow';

const navigateToItem = path => {
  window.location.href = path;
};

function isExternal(link) {
  return link.startsWith('http') || link.startsWith('https');
}

const NavItem = styled.li`
  color: ${props => props.theme.color.tertiary};
  font-family: ${props => props.theme.font.sourceSans};
  font-size: 18px;
  font-weight: ${props => props.theme.fontWeight.bold};
  padding: 0 20px;
  cursor: pointer;

  span {
    padding: 20px 0;
    border-bottom: ${props => props.isChild ? 'none' : `1px solid ${props.theme.color.borderPrimaryOverlay}`};
    width: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;
const MobileNavLink = ({ close, link, navigateToMenu, isChild }) =>
  link.subNav && link.subNav.length > 0 ? (
    <NavItem onClick={() => navigateToMenu(link)} isChild={isChild}>
      <span>
        {link.mobileText || link.text}
        <Arrow direction="right" fill="#fff" size={18} />
      </span>
    </NavItem>
  ) : (
    <NavItem onClick={() => navigateToItem(link.path)} isChild={isChild}>
      <span>
        {link.mobileText || link.text}
        {isExternal(link.path) && <ExternalArrow />}
      </span>
    </NavItem>
  );

MobileNavLink.propTypes = {
  close: PropTypes.func,
  link: PropTypes.shape({
    path: PropTypes.string,
    text: PropTypes.string.isRequired,
    subNav: PropTypes.array
  }),
  navigateToMenu: PropTypes.func.isRequired,
  isChild: PropTypes.bool.isRequired
};

export default MobileNavLink;
