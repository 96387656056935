import React from 'react';
import { Redirect } from '@reach/router';

import { logout } from '../../../globalUtils/api';

class Logout extends React.PureComponent {
  componentDidMount() {
    logout();
  }

  render() {
    return <Redirect noThrow to="/login" />;
  }
}

export default Logout;
