import React from 'react';
import Helmet from 'react-helmet';
import { Root, Routes } from 'react-static';
import { Router } from '@reach/router';
import { ThemeProvider } from 'styled-components';

import asyncComponent from './components/global/AsyncComponent';
import theme from './styles/theme';
import GlobalStyles from './styles/global';
import Login from './components/Admin/Login';
import Logout from './components/Admin/Logout';

const defaultSchemaMeta = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'Lochinvar, LLC',
  logo: '',
  url: 'https://lochinvar.com',
  description: 'High efficiency Water Heaters, Boilers and Pool Heaters',
  sameAs: [
    'https://www.facebook.com/LochinvarLLC',
    'https://twitter.com/LochinvarLLC',
    'https://www.linkedin.com/company/lochinvar-corporation',
    'https://www.youtube.com/user/LochinvarCorporation'
  ],
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '+1-877-554-5544',
    contactType: 'Customer Service',
    contactOption: 'TollFree',
    areaServed: 'United States'
  },
  address: {
    '@type': 'PostalAddress',
    addressCountry: 'United States',
    addressLocality: 'Lebanon',
    addressRegion: 'TN',
    postalCode: '37090',
    streetAddress: '300 Maddox Simpson Pkwy.'
  }
};

const isProd = process.env.LOCH_ENV === 'production';
const Admin = isProd
  ? null
  : asyncComponent(() => import('./components/Admin'));

const App = () => (
  <Root scrollToHashDuration={0} scrollToHashOffset={-70}>
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-TB7FSGW"
        title="GTM No Script"
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyles />
        <main>
          <Helmet defaultTitle="Lochinvar" titleTemplate="%s | Lochinvar">
            <meta charSet="UTF-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <meta
              name="facebook-domain-verification"
              content="eku8g33gujido8uqsxohwl7ee3c9gz"
            />
            <link
              href="https://fonts.googleapis.com/css?family=Montserrat:300,700|Source+Sans+Pro:200,400,400i,600,700,700i,900"
              rel="stylesheet"
            />
            <link
              rel="alternate"
              href="https://lochinvar.com"
              hrefLang="x-default"
            />
            <link
              rel="alternate"
              href="https://lochinvar.ltd.uk"
              hrefLang="en-GB"
            />
            <link
              rel="alternate"
              href="https://lochinvar.ltd.uk"
              hrefLang="en-IE"
            />
            <link
              rel="alternate"
              href="https://lochinvar.ltd.uk"
              hrefLang="ga-IE"
            />
            <script type="application/ld+json">
              {JSON.stringify(defaultSchemaMeta)}
            </script>
            {/* Adobe DTM - This is used for MultiView */}
            <script
              src="https://assets.adobedtm.com/175f7caa2b90/e60160fa2e63/launch-b250b63b97e1.min.js"
              async
            />
            {/* END Adobe DTM */}
            {/* GTM */}
            <script>
              {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-TB7FSGW');
              `}
            </script>
            {/* END GTM */}
            {/* GA Tagmanager V4 */}
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-QRWH052FB4"
            />
            <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                  dataLayer.push(arguments);
                }
                gtag('js', new Date());

                gtag('config', 'G-QRWH052FB4');
              `}
            </script>
            {/* END GA Tagmanager V4 */}
            <script>
              {`
                  (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:1104902,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);

                  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                  `}
            </script>
            <script src="https://www.google.com/recaptcha/api.js" async defer />
            <script>
              {`
                (function(w,d,t,u,n,s,e){w['SwiftypeObject']=n;w[n]=w[n]||function(){
                (w[n].q=w[n].q||[]).push(arguments);};s=d.createElement(t);
                e=d.getElementsByTagName(t)[0];s.async=1;s.src=u;e.parentNode.insertBefore(s,e);
                })(window,document,'script','//s.swiftypecdn.com/install/v2/st.js','_st');
                _st('install','4cQ58oCp5fK3RQHSQJyo','2.0.0');
              `}
            </script>
            {/* <!-- OneTrust Cookies Consent Notice start for [lochinvar.com](http://lochinvar.com/) --> */}
            <script
              src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
              data-document-language="true"
              type="text/javascript"
              charset="UTF-8"
              data-domain-script="3ea69c31-8ea1-4406-8ff8-00887020266b-test"
            />
            <script type="text/javascript">
              {`
                function OptanonWrapper() { }
              `}
            </script>
            {/* <!-- OneTrust Cookies Consent Notice end for [lochinvar.com](http://lochinvar.com/) --> */}
          </Helmet>
          <Router>
            <Admin path="/admin/*" />
            <Login path="/login" />
            <Logout path="/logout" />
            <Routes default />
          </Router>
          <div id="videoOverlay" />
        </main>
      </React.Fragment>
    </ThemeProvider>
  </Root>
);

export default App;
